












import { defineComponent, PropType } from '@vue/composition-api';
import { BuildStep, VariableInput } from '@/model/store';
import { useNewProjectsStore } from '@/stores/new-project';
import AppVariableInputs from '@/components/wizard/forms/inputs/VariableInputs.vue';

export default defineComponent({
  name: 'Variables',
  components: { AppVariableInputs },
  props: {
    buildStep: Object as PropType<BuildStep>,
  },
  setup(props) {
    const updateVariables = (variables: VariableInput[]) => {
      const store = useNewProjectsStore();
      store.updateBuildStep({ id: props.buildStep?.id, variables });
    };

    return {
      updateVariables,
    };
  },
});
