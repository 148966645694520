
















































import { defineComponent, ref, watch, PropType } from '@vue/composition-api';
import { VariableInput } from '@/model/store';

function emptyInput(): VariableInput {
  return { name: '', value: '', secret: false, editable: true };
}

export default defineComponent({
  name: 'VariableInputs',
  props: {
    initial: {
      type: Array as PropType<VariableInput[]>,
      default: () => [],
    },
    addLabel: String,
    hasSecretIcon: Boolean,
    validationCallback: {
      type: Function as PropType<(a: VariableInput) => string>,
    },
  },
  emits: ['change'],
  setup(props, context) {
    const initial = Array.from(props.initial);
    initial.push(emptyInput());
    const inputs = ref(initial);
    const add = () => {
      inputs.value.push(emptyInput());
    };
    const remove = (index: number) => {
      inputs.value.splice(index, 1);
      emitChange();
    };
    const toggleSecret = (index: number) => {
      const input = inputs.value[index];
      if (input) {
        inputs.value.splice(index, 1, { ...input, secret: !input.secret });
      }
      emitChange();
    };
    const nameValidationErrors = (input: VariableInput): string[] => {
      const errors: string[] = [];
      if (inputs.value.filter(({ name }) => name && name === input.name).length > 1) {
        errors.push('Variable name should be unique');
      }
      if (props.validationCallback) {
        const error = props.validationCallback(input);
        if (error) {
          errors.push(error);
        }
      }
      return errors;
    };
    const secretIcon = (secret: boolean) => (secret ? 'mdi-incognito' : 'mdi-incognito-off');
    const emitChange = () => {
      const cleanInputs = inputs.value.filter((input) => input.name && input.value);
      context.emit('change', cleanInputs);
    };

    watch(inputs, emitChange, { deep: true });

    return {
      inputs,
      add,
      remove,
      toggleSecret,
      secretIcon,
      nameValidationErrors,
    };
  },
});
