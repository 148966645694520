


































import { defineComponent, onMounted, PropType } from '@vue/composition-api';
import { BuildStep } from '@/model/store';
import { useNewProjectsStore } from '@/stores/new-project';
import { runSafe } from '@/utils';

export default defineComponent({
  name: 'Versions',
  props: {
    buildStep: Object as PropType<BuildStep>,
  },
  setup(props, { refs }) {
    const newProjectsStore = useNewProjectsStore();
    const updateRuntime = (version: string) => {
      newProjectsStore.updateBuildStep({ id: props.buildStep?.id, runtimeVersion: version });
    };
    const updateBuildTool = (version: string) => {
      newProjectsStore.updateBuildStep({ id: props.buildStep?.id, buildToolVersion: version });
    };
    const validate = () => {
      runSafe(refs.runtime, 'validate');
      runSafe(refs.buildTool, 'validate');
    };

    onMounted(() => {
      validate();
    });

    return {
      validate,
      updateRuntime,
      updateBuildTool,
    };
  },
});
