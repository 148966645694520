import { render, staticRenderFns } from "./ConfigureBuild.vue?vue&type=template&id=71a76899&scoped=true"
import script from "./ConfigureBuild.vue?vue&type=script&lang=ts"
export * from "./ConfigureBuild.vue?vue&type=script&lang=ts"
import style0 from "./ConfigureBuild.vue?vue&type=style&index=0&id=71a76899&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a76899",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VExpansionPanels,VTextField})
