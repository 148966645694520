

































import draggable from 'vuedraggable';
import { defineComponent, computed } from '@vue/composition-api';
import { useNewProjectsStore } from '@/stores/new-project';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { BuildStep } from '@/model/store';
import AppBuildStep from '@/components/wizard/steps/build/buildStep/BuildStep.vue';
import AppBuildStepAdd from '@/components/wizard/steps/build/buildStep/BuildStepAdd.vue';
import AppWizardTitle from '@/components/wizard/forms/title/WizardTitle.vue';

export default defineComponent({
  name: 'SetupConfigureBuild',
  components: {
    draggable,
    AppBuildStep,
    AppBuildStepAdd,
    AppWizardTitle,
  },
  setup() {
    const setupWizardStore = useSetupWizardStore();
    const newProjectsStore = useNewProjectsStore();

    const panels = computed<number>({
      get() {
        return setupWizardStore.selectedBuildStepPanel || 0;
      },
      set(value) {
        setupWizardStore.selectBuildStepPanel(value);
      },
    });
    const buildSteps = computed<BuildStep[]>({
      get() {
        return newProjectsStore.buildSteps || [];
      },
      set(value) {
        newProjectsStore.setBuildSteps(value);
      },
    });
    const deploymentFolder = computed<string>({
      get() {
        return newProjectsStore.deployStep?.deploymentFolder || '';
      },
      set(value) {
        newProjectsStore.setDeploymentFolder(value);
      },
    });

    return {
      panels,
      buildSteps,
      deploymentFolder,
    };
  },
});
