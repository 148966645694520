












import { defineComponent, PropType } from '@vue/composition-api';
import { BuildStep } from '@/model/store';
import { useNewProjectsStore } from '@/stores/new-project';
import MultipleInputs from '@/components/wizard/forms/inputs/MultipleInputs.vue';

export default defineComponent({
  name: 'Commands',
  components: { MultipleInputs },
  props: {
    buildStep: Object as PropType<BuildStep>,
  },
  setup(props) {
    const updateCommands = (commands: string[]) => {
      const store = useNewProjectsStore();
      store.updateBuildStep({ id: props.buildStep?.id, commands });
    };

    return {
      updateCommands,
    };
  },
});
