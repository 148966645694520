


















import { defineComponent, computed, onMounted, PropType } from '@vue/composition-api';
import { BuildStep } from '@/model/store';
import { useNewProjectsStore } from '@/stores/new-project';
import { runSafe } from '@/utils';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';
import AppBuildVersions from '@/components/wizard/steps/build/buildStep/parts/Versions.vue';
import AppBuildCommands from '@/components/wizard/steps/build/buildStep/parts/Commands.vue';
import AppBuildVariables from '@/components/wizard/steps/build/buildStep/parts/Variables.vue';

export default defineComponent({
  name: 'BuildStep',
  components: {
    AppExpansionPanel,
    AppBuildVersions,
    AppBuildCommands,
    AppBuildVariables,
  },
  props: {
    buildStep: Object as PropType<BuildStep>,
  },
  setup(props, { refs }) {
    const showVersions = computed<boolean>(() => !!(props.buildStep?.runtime || props.buildStep?.buildTool));
    const showCommands = computed<boolean>(() => Array.isArray(props.buildStep?.commands));
    const removeBuildStep = () => {
      props.buildStep && useNewProjectsStore().removeBuildStep(props.buildStep.id);
    };
    const onChange = () => {
      runSafe(refs.versions, 'validate');
    };

    onMounted(() => {
      onChange();
    });

    return {
      showVersions,
      showCommands,
      removeBuildStep,
      onChange,
    };
  },
});
