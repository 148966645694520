

















import { storeToRefs } from 'pinia';
import { defineComponent, ref } from '@vue/composition-api';
import { BuildStepDescription } from 'ionos-space-api-v4';
import { useNewProjectsStore } from '@/stores/new-project';
import { useMasterDataStore } from '@/stores/master-data';
import { useSetupWizardStore } from '@/stores/setup-wizard';

export default defineComponent({
  name: 'BuildStepAdd',
  setup() {
    const newProjectsStore = useNewProjectsStore();
    const masterDataStore = useMasterDataStore();
    const setupWizardStore = useSetupWizardStore();
    const loading = ref(false);
    const { buildSteps } = storeToRefs(masterDataStore);

    const addStep = async (item: BuildStepDescription) => {
      loading.value = true;
      const buildStep = await setupWizardStore.getBuildStep(item.name);
      if (buildStep) {
        newProjectsStore.setBuildStep(buildStep);
      }
      loading.value = false;
    };

    return {
      buildSteps,
      loading,
      addStep,
    };
  },
});
